import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { HttpClientModule} from '@angular/common/http';
import { PremiocolaboraComponent } from './components/premiocolabora/premiocolabora.component';
import { PremioprincComponent } from './components/premioprinc/premioprinc.component';
import { RemanentesComponent } from './components/remanentes/remanentes.component';
import { FormComponent } from './components/form/form.component';
import { ColaboraFormComponent } from './components/colabora-form/colabora-form.component';
import { ContactComponent } from './components/contact/contact.component';
import { ReglamentobecarioComponent } from './components/reglamentobecario/reglamentobecario.component';
import { PrivacidadComponent } from './components/privacidad/privacidad.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { ConvenioComponent } from './components/convenio/convenio.component';
import { BasesorteoComponent } from './components/basesorteo/basesorteo.component';
import { MecanicaComponent } from './components/mecanica/mecanica.component';
import { FormsModule } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeMX from '@angular/common/locales/es-MX';
import { FaqsComponent } from './components/faqs/faqs.component';
import { DatesComponent } from './components/dates/dates.component';
import { RecuperarPassComponent } from './components/recuperar-pass/recuperar-pass.component';
import { MenuLoginComponent } from './components/menu-login/menu-login.component';
import { PostalComponent } from './components/postal/postal.component';
import { ComparteComponent } from './components/comparte/comparte.component';
import { ComparteTextComponent } from './components/comparte-text/comparte-text.component';
import { ConsultaBoletosComponent } from './components/consulta-boletos/consulta-boletos.component';
import { BlogComponent } from './components/blog/blog.component';


import { GanadoresPrincipalesComponent } from './components/ganadores-principales/ganadores-principales.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ListaegresadosComponent } from './components/listaegresados/listaegresados.component';

import { VentamesessininteresesComponent } from './components/ventamesessinintereses/ventamesessinintereses.component';
import { FormwinnersComponent } from './components/formwinners/formwinners.component';
import { LoginComponent } from './components/login/login.component';
import { FormularioDocumentosComponent } from './components/formulario-documentos/formulario-documentos.component';
import { PuntosdeventaComponent } from './components/puntosdeventa/puntosdeventa.component';
import { BasesoportunosComponent } from './components/basesoportunos/basesoportunos.component';
import { ParticiparSorteoOportunoComponent } from './components/participar-sorteo-oportuno/participar-sorteo-oportuno.component';
import { ThanksColaboradorComponent } from './components/thanks-colaborador/thanks-colaborador.component';
import { ThanksEgresadoComponent } from './components/thanks-egresado/thanks-egresado.component';
import { GanadoressorteosoportunosComponent } from './components/ganadoressorteosoportunos/ganadoressorteosoportunos.component';
import { ParticipantessorteoptComponent } from './components/participantessorteopt/participantessorteopt.component';
import { BasesegresadosComponent } from './components/basesegresados/basesegresados.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { Participantesso2Component } from './components/participantesso2/participantesso2.component';
import { MainloginComponent } from './components/mainlogin/mainlogin.component';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { GalerianuevaComponent } from './components/galerianueva/galerianueva.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ParticipantesEgresadosComponent } from './components/participantes-egresados/participantes-egresados.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ParticipantesLealtadComponent } from './components/participantes-lealtad/participantes-lealtad.component';
import { ParticipantesAmigoComponent } from './components/participantes-amigo/participantes-amigo.component';
import { ParticipantesSONComponent } from './components/participantes-son/participantes-son.component';
import { Ganadorso2Component } from './components/ganadorso2/ganadorso2.component';
import { GanadorresegresadosComponent } from './components/ganadorresegresados/ganadorresegresados.component';


registerLocaleData(localeMX);



 


@NgModule({

  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NosotrosComponent,
    GaleriaComponent,
    PremiocolaboraComponent,
    PremioprincComponent,
    RemanentesComponent,
    FormComponent,
    ColaboraFormComponent,
    ContactComponent,
    ReglamentobecarioComponent,
    PrivacidadComponent,
    TerminosComponent,
    ConvenioComponent,
    BasesorteoComponent,
    MecanicaComponent,
    FaqsComponent,
    DatesComponent,
    RecuperarPassComponent,
    MenuLoginComponent,
    PostalComponent,
    ComparteComponent,
    ComparteTextComponent,
    ConsultaBoletosComponent,
    BlogComponent,
    GanadoresPrincipalesComponent,
    ListaegresadosComponent,
    VentamesessininteresesComponent,
    FormwinnersComponent,
    LoginComponent,
    FormularioDocumentosComponent,

    PuntosdeventaComponent,

    BasesoportunosComponent,

    ParticiparSorteoOportunoComponent,
     ThanksColaboradorComponent,
     ThanksEgresadoComponent,
     GanadoressorteosoportunosComponent,
     ParticipantessorteoptComponent,
     BasesegresadosComponent,
     Participantesso2Component,
     MainloginComponent,
     DocumentosComponent,
     GalerianuevaComponent,
     ParticipantesEgresadosComponent,
     ParticipantesLealtadComponent,
     ParticipantesAmigoComponent,
     ParticipantesSONComponent,
     Ganadorso2Component,
     GanadorresegresadosComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxWhastappButtonModule,
    SlickCarouselModule,
    NgxYoutubePlayerModule,
    FontAwesomeModule,
    NgxPaginationModule

  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-MX' }],
  bootstrap: [AppComponent]
})
export class AppModule { }


